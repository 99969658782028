import LoginPage from 'containers/auth/LoginPage/Loadable';
import LogoutPage from 'containers/auth/LogoutPage/Loadable';
import Dashboard from 'containers/Dashboard/Loadable';
import Tenant from 'containers/Tenant/Loadable';
import Unsubscribe from 'containers/Unsubscribe/Loadable';
import FormList from 'containers/FormList/Loadable';
import { Forbidden, Error, NotFound } from 'components/pages';
import FormBuilder from 'containers/FormBuilder/Loadable';
import RecruitLoginPage from 'containers/auth/RecruitLoginPage/Loadable';
import Notifications from 'containers/Notifications/Loadable';
import TenantConfig from 'containers/TenantConfig/Loadable';
import Users from 'containers/Users/Loadable';

const routes = [
  { key: 1, isPrivate: false, path: '/login', element: LoginPage },
  { key: 2, isPrivate: false, path: '/logout', element: LogoutPage },
  { key: 3, isPrivate: true, path: '/', element: Dashboard },
  { key: 4, isPrivate: true, path: 'dashboard', element: Dashboard },
  // { key: 5, isPrivate: true, path: '/form', element: FormApp },
  { key: 6, isPrivate: false, path: '/unsubscribe', element: Unsubscribe, skipTest: true },
  { key: 7, isPrivate: false, path: '/forbidden', element: Forbidden, skipTest: true },
  { key: 8, isPrivate: false, path: '/error', element: Error, skipTest: true },
  { key: 9, isPrivate: false, path: '*', element: NotFound, skipTest: true },
  { key: 10, isPrivate: true, path: '/users', element: Users },
  { key: 11, isPrivate: true, path: '/tenant', element: Tenant },
  { key: 12, isPrivate: true, path: '/form', element: FormList },
  { key: 13, isPrivate: true, path: '/form-builder/:formId', element: FormBuilder },
  { key: 14, isPrivate: false, path: '/auth/recruit', element: RecruitLoginPage },
  // Enable this line to enable recruit login testing in local debug
  // { key: 14, isPrivate: false, path: '/auth/aad', element: RecruitLoginPage },
  { key: 15, isPrivate: true, path: '/notifications', element: Notifications },
  { key: 16, isPrivate: true, path: '/tenant/:tenantId', element: TenantConfig },
];

export const privateRoutes = routes.filter((route) => route.isPrivate);
export const publicRoutes = routes.filter((route) => !route.isPrivate);

export default routes;
