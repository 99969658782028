/**
 *
 * Avatar
 *
 */
import classNames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useMemo, useRef } from 'react';
import getInitial from 'utils/getInitial';

const sizes = {
  xxl: 'h-24 w-24 text-3xl',
  xl: 'h-20 w-20 text-2xl',
  lg: 'h-9 w-9 text-sm',
  md: 'h-6 w-6 text-xs',
  sm: 'h-5 w-5 text-[.625rem]',
};
const Index = (props) => {
  const { size, data, isInitials, customInitial, role = 'avatar', showRing, isInterviewer, customShape } = props;
  const fullName = useMemo(() => (data ? getInitial(data) : ''), [data]);
  const ref = useRef();
  // dev env update comment

  const initialContent = useMemo(() => {
    if (customInitial) {
      return customInitial;
    }
    if (fullName && fullName.length) {
      const names = fullName.split(' ');
      return names
        .slice(0, 2)
        .map((d) => d.substring(0, 1))
        .join('');
    }
    return ((data && data.id) || '').toString().substring(0, 2);
  });

  const displayPicture = useMemo(() => (data ? data.profile_pic : ''), [data]);

  if (isInitials || !displayPicture || customInitial) {
    return (
      <div ref={ref} className={classNames('group relative', get(sizes, size, sizes.md))}>
        <div
          role={role}
          className={classNames(
            isInterviewer ? 'bg-purple-100' : 'bg-sky-200',
            'inline-flex items-center justify-center',
            customShape || 'rounded-full',
            get(sizes, size, sizes.md),
            showRing && 'ring-1 ring-white',
          )}
        >
          <div className={classNames('leading-none text-gray-900 font-normal')}>{initialContent}</div>
        </div>
      </div>
    );
  }

  return (
    <div ref={ref} className={classNames('group relative', get(sizes, size, sizes.md))}>
      <img
        role={role}
        className={classNames(
          'inline-block object-cover',
          customShape || 'rounded-full',
          get(sizes, size, sizes.md),
          showRing && 'ring-1 ring-white',
        )}
        src={displayPicture}
        alt="avatar"
      />
    </div>
  );
};

Index.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    username: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    middle_name: PropTypes.string,
    profile_pic: PropTypes.string,
  }),
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'xxl']),
  isInitials: PropTypes.bool,
  customInitial: PropTypes.string,
  showRing: PropTypes.bool,
  isInterviewer: PropTypes.bool,
  customShape: PropTypes.string,
};

Index.defaultProps = {
  data: {},
  size: 'md',
  isInitials: false,
  customInitial: '',
  isInterviewer: false,
};

export default memo(Index);
